import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  Grid2,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import MUIDataTable from 'mui-datatables';
import { useEffect, useState } from 'react';
import {
  getEquipments,
  getIcons,
  getMeasurements,
  getStandardSamplePoints,
} from '../../../../helpers/api';
import { Add, Close, Delete } from '@mui/icons-material';
import { openNotification } from '../../../../helpers/notification';
import { useAppSelector, useAppDispatch } from '../../../../redux/reduxstore';
import { onStoreCreateFacilityDetails } from '../../../../redux/slices/facilityCreateSlice';

export default function CreateFacilityStep2({ onNext, onPrev }) {
  const store = useAppSelector((state) => state.facilityCreateReducer);
  const dispatch = useAppDispatch();

  const selectionModes = ['standard', 'others'];
  const [selectionMode, setSelectionMode] = useState(selectionModes[0]);
  const [iconData, setIconData] = useState([]);
  const [iconLoading, setIconLoading] = useState(false);
  const [base64String, setBase64String] = useState('');
  const [samplePointList, setSamplePointList] = useState([]);
  const [measurementLoading, setMeasurementLoading] = useState(false);
  const [measurementData, setMeasurementData] = useState([]);
  const [equipmentLoading, setEquipmentLoading] = useState(false);
  const [equipmentData, setEquipmentData] = useState([]);
  const [dialogIcons, setDialogIcons] = useState(false);
  const [standardSamplePointData, setStandardSamplePointData] = useState([]);
  const [standardSamplePointLoading, setStandardSamplePointLoading] = useState([]);

  const formik = useFormik({
    initialValues: {
      standardSamplePointId: '',
      samplePointName: '',
      measurementId: '',
      equipmentId: '',
      measureNoise: '0',
      latitude: '',
      longitude: '',
      icon: '',
      checkCount: '1',
    },
    onSubmit: (values) => handleAddToList(values),
  });

  const getInitialData = () => {
    setSamplePointList(store?.samplePoints);
  };

  const handleAddToList = (values) => {
    const payload = {
      standardSamplePointId: values?.standardSamplePointId,
      samplePointName: values?.samplePointName,
      coordinates: {
        latitude: values?.latitude,
        longitude: values?.longitude,
      },
      checkCount: Number(values?.checkCount),
      measurementId: values?.measurementId,
      icon: base64String,
      equipmentId: values?.equipmentId,
      measureNoise: Number(values?.measureNoise),
    };

    let exists = undefined;

    if (payload?.standardSamplePointId) {
      exists = samplePointList?.find(
        (x) =>
          x?.standardSamplePointId === payload?.standardSamplePointId &&
          x?.measurementId === payload?.measurementId
      );
    } else {
      exists = samplePointList?.find(
        (x) =>
          x?.samplePointName === payload?.samplePointName &&
          x?.measurementId === payload?.measurementId
      );
    }

    if (exists) {
      return openNotification({
        type: 'error',
        title: 'The item already exists in the list',
      });
    } else {
      setSamplePointList((prev) => [...prev, payload]);
      return openNotification({
        type: 'success',
        title: 'The item has been added to list',
      });
    }
  };

  const saveTempData = () => {
    const reduxPayload = {
      //step 1
      facilityName: store?.facilityName,
      registrationNo: store?.registrationNo,
      address: store?.address,
      coordinates: store?.coordinates,
      facilityCategoryId: store?.facilityCategoryId,
      sectorId: store?.sectorId,
      zoneId: store?.zoneId,
      stateId: store?.stateId,
      lgaId: store?.lgaId,
      status: store?.status,
      productId: store?.productId,
      productName: store?.productName,
      rawMaterialId: store?.rawMaterialId,
      rawMaterialName: store?.rawMaterialName,
      wasteId: store?.wasteId,
      wasteName: store?.wasteName,
      facilityEia: store?.facilityEia,
      eiaCert: store?.store,
      remarks: store?.store,

      //step 2
      samplePoints: samplePointList,

      //step 3
      consultantId: store?.consultantId,
      facilityPersonel: store?.facilityPersonel,
    };

    dispatch(onStoreCreateFacilityDetails(reduxPayload));
  };

  const handleSubmit = () => {
    if (samplePointList?.length < 1) {
      return openNotification({
        type: 'error',
        title: 'You must select or enter at least one sample point',
      });
    } else {
      saveTempData();
      onNext(2);
    }
  };

  const handlePrev = () => {
    saveTempData();
    onPrev(0);
  };

  const renderSelectionMode = () => {
    return (
      <ToggleButtonGroup
        size="small"
        color="primary"
        value={selectionMode}
        exclusive
        sx={{ borderRadius: '50px' }}
        onChange={(e, value) => {
          if (!value) setSelectionMode(selectionModes[0]);
          else setSelectionMode(value);

          formik.setValues({
            ...formik.values,
            standardSamplePointId: '',
            samplePointName: '',
          });
        }}
        aria-label="Platform"
      >
        {selectionModes?.map((mode) => (
          <ToggleButton value={mode} sx={{ borderRadius: '50px', textTransform: 'capitalize' }}>
            {mode}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    );
  };

  const handleRemoveFromList = (selected) => {
    const index = samplePointList.indexOf(selected);
    const newSamplePointList = [...samplePointList];
    newSamplePointList.splice(index, 1);
    setSamplePointList(newSamplePointList);
  };

  const fetchStandardSamplePoint = () => {
    setStandardSamplePointLoading(true);
    getStandardSamplePoints()
      .then((response) => {
        const responseData = response?.result?.data;
        setStandardSamplePointData(responseData);
      })
      .catch((err) => {})
      .finally(() => setStandardSamplePointLoading(false));
  };

  const fetchMeasurement = () => {
    setMeasurementLoading(true);
    getMeasurements()
      .then((response) => {
        const responseData = response?.result?.data;
        setMeasurementData(responseData);
      })
      .catch((err) => {})
      .finally(() => setMeasurementLoading(false));
  };

  const fetchEquipment = () => {
    setEquipmentLoading(true);
    getEquipments()
      .then((response) => {
        const responseData = response?.result?.data;
        responseData?.unshift({ equipmentId: '', equipmentName: 'No Equipment' });
        setEquipmentData(responseData);
      })
      .catch((err) => {})
      .finally(() => setEquipmentLoading(false));
  };

  const fetchIcon = () => {
    setIconLoading(true);
    getIcons()
      .then((response) => {
        const responseData = response?.result?.data;
        const icon = responseData?.map((x) => {
          return x?.iconSrc;
        });
        setIconData(icon);
      })
      .catch((err) => {})
      .finally(() => setIconLoading(false));
  };

  const renderPreview = () => {
    const columns = [
      {
        name: 'icon',
        label: 'Icon',
        options: {
          customHeadLabelRender: (a) => {
            return (
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {a.label}
              </Typography>
            );
          },
          customBodyRenderLite: (index) => {
            const icon = samplePointList[index]?.icon;
            return (
              <Box>
                {icon && (
                  <Box>
                    <img
                      src={icon}
                      style={{
                        objectFit: 'cover',
                        width: '30px',
                        height: '30px',
                      }}
                    />
                  </Box>
                )}
              </Box>
            );
          },
        },
      },
      {
        name: '',
        label: 'Sample Point',
        options: {
          customHeadLabelRender: (a) => {
            return (
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {a.label}
              </Typography>
            );
          },
          customBodyRenderLite: (index) => {
            const record = samplePointList[index];
            if (record?.standardSamplePointId) {
              const sspId = standardSamplePointData?.find(
                (x) => x.standardSamplePointId === record?.standardSamplePointId
              );
              return <Typography variant="body2">{sspId?.standardSamplePointName}</Typography>;
            }

            return <Typography variant="body2">{record?.samplePointName}</Typography>;
          },
        },
      },
      {
        name: '',
        label: 'Measurement',
        options: {
          customHeadLabelRender: (a) => {
            return (
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {a.label}
              </Typography>
            );
          },
          customBodyRenderLite: (index) => {
            const measurement = measurementData?.find(
              (x) => x?.measurementId === samplePointList[index]?.measurementId
            );
            return (
              <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
                {measurement?.measurementName}
              </Typography>
            );
          },
        },
      },
      {
        name: 'checkCount',
        label: 'Runs',
        options: {
          customHeadLabelRender: (a) => {
            return (
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {a.label}
              </Typography>
            );
          },
          customBodyRenderLite: (index) => {
            return <Typography variant="body2">{samplePointList[index]?.checkCount}</Typography>;
          },
        },
      },
      {
        name: '',
        label: 'Equipment',
        options: {
          customHeadLabelRender: (a) => {
            return (
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {a.label}
              </Typography>
            );
          },
          customBodyRenderLite: (index) => {
            const record = samplePointList[index];
            const equipment = equipmentData?.find((x) => x?.equipmentId === record?.equipmentId);
            if (equipment) {
              return <Typography variant="body2">{equipment?.equipmentName}</Typography>;
            }
            return null;
          },
        },
      },
      {
        name: 'coordinates',
        label: 'Coordinates',
        options: {
          customHeadLabelRender: (a) => {
            return (
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {a.label}
              </Typography>
            );
          },
          customBodyRenderLite: (index) => {
            const longitude = samplePointList[index]?.coordinates?.longitude;
            const latitude = samplePointList[index]?.coordinates?.latitude;
            return (
              <Box>
                <Box>
                  <Typography
                    component={'span'}
                    variant="body2"
                    color="textSecondary"
                    sx={{
                      display: 'inline-block',
                      width: '40px',
                    }}
                  >
                    Lat:
                  </Typography>
                  <Typography
                    component={'span'}
                    variant="body2"
                    sx={{
                      width: '40px',
                    }}
                  >
                    {latitude}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    component={'span'}
                    variant="body2"
                    color="textSecondary"
                    sx={{
                      display: 'inline-block',
                      width: '40px',
                    }}
                  >
                    Lng:
                  </Typography>
                  <Typography
                    component={'span'}
                    variant="body2"
                    sx={{
                      width: '40px',
                    }}
                  >
                    {longitude}
                  </Typography>
                </Box>
              </Box>
            );
          },
        },
      },
      {
        name: '',
        label: 'Action',
        options: {
          customHeadLabelRender: (a) => {
            return (
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {a.label}
              </Typography>
            );
          },
          customBodyRenderLite: (index) => {
            const selectPreview = samplePointList[index];
            return (
              <IconButton size="small" onClick={() => handleRemoveFromList(selectPreview)}>
                <Delete color="error" fontSize="small" />
              </IconButton>
            );
          },
        },
      },
    ];

    return (
      <Paper
        sx={{
          overflowY: 'scroll',
          boxShadow: `0px 1px 1px rgba(0, 0, 0, 0.1), 0px 2px 10px rgba(0, 0, 0, 0.15)`,
        }}
      >
        <MUIDataTable
          title=""
          data={samplePointList}
          columns={columns}
          options={{
            elevation: 0,
            search: false,
            filter: false,
            download: false,
            print: false,
            viewColumns: false,
            selectableRows: 'none',
            responsive: 'standard',
            pagination: false,
            fixedHeader: true,
            tableBodyHeight: '300px',
            setRowProps: (ev, dataIndex) => {
              return {
                style: dataIndex % 2 === 0 ? { background: '#f5f7ff' } : { background: '#FFFFFFF' },
              };
            },
          }}
        />
      </Paper>
    );
  };

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    fetchMeasurement();
  }, []);

  useEffect(() => {
    fetchEquipment();
  }, []);

  useEffect(() => {
    fetchIcon();
  }, []);

  useEffect(() => {
    fetchStandardSamplePoint();
  }, []);

  return (
    <Box>
      <Box>
        <form onSubmit={formik.handleSubmit}>
          <Grid2 container spacing={2}>
            <Grid2 size={{ lg: 12, md: 12, sm: 12, xs: 12 }}>
              <Typography variant="body1" color="textPrimary">
                <strong>Sample Point</strong>
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Enter sample point information. Click on Add button to add multiple samplepoints
              </Typography>
            </Grid2>
            <Grid2 size={{ lg: 12, md: 12, sm: 12, xs: 12 }}>
              <Box sx={{ display: 'flex', gap: 5, alignItems: 'center' }}>
                <Chip
                  label="Select Icon"
                  variant="outlined"
                  color="info"
                  onClick={() => setDialogIcons(true)}
                />
                {base64String && (
                  <Box>
                    <img
                      src={base64String}
                      style={{ objectFit: 'cover', width: '100px', height: '100px' }}
                    />
                  </Box>
                )}
              </Box>
            </Grid2>

            {selectionMode === selectionModes[0] && (
              <Grid2 size={{ lg: 12, md: 12, sm: 12, xs: 12 }}>
                <FormControl fullWidth>
                  <InputLabel>Standard Sample Point *</InputLabel>
                  <Select
                    name="standardSamplePointId"
                    label="Standard Sample Point"
                    value={formik.values.standardSamplePointId}
                    required
                    onChange={formik.handleChange}
                    startAdornment={standardSamplePointLoading && <CircularProgress size={20} />}
                    endAdornment={<Box sx={{ marginRight: '20px' }}>{renderSelectionMode()}</Box>}
                    sx={{ backgroundColor: '#FFFFFF' }}
                  >
                    {standardSamplePointData?.map((standardSamplePoint) => (
                      <MenuItem
                        key={standardSamplePoint?.standardSamplePointId}
                        value={standardSamplePoint?.standardSamplePointId}
                      >
                        {standardSamplePoint?.standardSamplePointName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid2>
            )}
            {selectionMode === selectionModes[1] && (
              <Grid2 size={{ lg: 12, md: 12, sm: 12, xs: 12 }}>
                <TextField
                  name={'samplePointName'}
                  label="Sample Point Name"
                  variant="outlined"
                  values={formik.values.samplePointName}
                  fullWidth
                  onChange={formik.handleChange}
                  required
                  sx={{ backgroundColor: '#FFFFFF' }}
                  slotProps={{
                    input: {
                      endAdornment: <Box sx={{ marginRight: '20px' }}>{renderSelectionMode()}</Box>,
                    },
                  }}
                />
              </Grid2>
            )}

            <Grid2 size={{ lg: 5, md: 5, sm: 4, xs: 12 }}>
              <FormControl fullWidth>
                <InputLabel>Measurement *</InputLabel>
                <Select
                  name="measurementId"
                  label="Measurement"
                  value={formik.values.measurementId}
                  required
                  onChange={formik.handleChange}
                  startAdornment={measurementLoading && <CircularProgress size={20} />}
                  sx={{ backgroundColor: '#FFFFFF' }}
                >
                  {measurementData?.map((measurement) => (
                    <MenuItem key={measurement?.measurementId} value={measurement?.measurementId}>
                      {measurement?.measurementName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid2>
            <Grid2 size={{ lg: 5, md: 5, sm: 4, xs: 12 }}>
              <FormControl fullWidth>
                <InputLabel>Equipment</InputLabel>
                <Select
                  name="equipmentId"
                  label="Equipment"
                  value={formik.values.equipmentId}
                  onChange={formik.handleChange}
                  startAdornment={equipmentLoading && <CircularProgress size={20} />}
                  sx={{ backgroundColor: '#FFFFFF' }}
                >
                  {equipmentData?.map((equipment) => (
                    <MenuItem key={equipment?.equipmentId} value={equipment?.equipmentId}>
                      {equipment?.equipmentName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid2>
            <Grid2 size={{ lg: 2, md: 2, sm: 4, xs: 12 }}>
              <TextField
                name="checkCount"
                label="No of Runs"
                variant="outlined"
                value={formik.values.checkCount}
                fullWidth
                disabled
                onChange={formik.handleChange}
                required
                sx={{ backgroundColor: '#FFFFFF' }}
              />
            </Grid2>
            <Grid2 size={{ lg: 6, md: 6, sm: 6, xs: 12 }}>
              <TextField
                name={'latitude'}
                label="Latitude"
                variant="outlined"
                value={formik.values.latitude}
                fullWidth
                onChange={formik.handleChange}
                //required
                sx={{ backgroundColor: '#FFFFFF' }}
              />
            </Grid2>
            <Grid2 size={{ lg: 6, md: 6, sm: 6, xs: 12 }}>
              <TextField
                name={'longitude'}
                label="Longitude"
                variant="outlined"
                value={formik.values.longitude}
                fullWidth
                onChange={formik.handleChange}
                //required
                sx={{ backgroundColor: '#FFFFFF' }}
              />
            </Grid2>
            <Grid2 size={{ lg: 12, md: 12, sm: 12, xs: 12 }}>
              <Button
                type="submit"
                variant="contained"
                color="info"
                startIcon={<Add />}
                sx={{ borderRadius: '50px', textTransform: 'none', width: '100px' }}
              >
                Add
              </Button>
            </Grid2>
            <Grid2 size={{ lg: 12, md: 12, sm: 12, xs: 12 }}>
              <br />
              {renderPreview()}
            </Grid2>
            <Grid2 size={{ lg: 12, md: 12, sm: 12, xs: 12 }}>
              <br />
              <Box>
                <Button
                  type="button"
                  variant="outlined"
                  size="large"
                  onClick={handlePrev}
                  sx={{ marginRight: '10px', textTransform: 'none', width: '100px' }}
                >
                  Previous
                </Button>
                <Button
                  type="button"
                  variant="contained"
                  size="large"
                  onClick={handleSubmit}
                  sx={{ textTransform: 'none', width: '100px' }}
                >
                  Next
                </Button>
              </Box>
            </Grid2>
          </Grid2>
        </form>
      </Box>

      {dialogIcons && (
        <Dialog open={dialogIcons} maxWidth="md">
          <Box sx={{ display: 'flex', justifyContent: 'end', padding: '0.5rem' }}>
            <IconButton onClick={() => setDialogIcons(false)}>
              <Close />
            </IconButton>
          </Box>
          <DialogContent>
            <Box sx={{ display: 'flex', gap: 3, flexWrap: 'wrap' }}>
              {iconLoading && (
                <Box component={'p'}>
                  <CircularProgress size={20} />
                </Box>
              )}
              {!iconLoading &&
                iconData?.length > 0 &&
                iconData?.map((item) => (
                  <Box
                    sx={{
                      border: '1px solid gray',
                      '&:hover': {
                        opacity: 0.4,
                      },
                      '&:active': {
                        opacity: 0.8,
                      },
                    }}
                    onClick={() => {
                      setBase64String(item);
                      setDialogIcons(false);
                    }}
                  >
                    <img src={item} style={{ objectFit: 'cover', width: '70px', height: '70px' }} />
                  </Box>
                ))}
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
}
