import { Close } from '@mui/icons-material';
import { Box, Dialog, IconButton, Typography } from '@mui/material';
import React from 'react';

export default function FacilityInvoice({ open, onClose, record }) {
  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <Box display="flex" alignItems={'center'}>
        <Typography flexGrow={1}></Typography>
        <Box padding="1rem">
          <IconButton onClick={() => onClose()}>
            <Close />
          </IconButton>
        </Box>
      </Box>
    </Dialog>
  );
}
