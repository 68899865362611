import { Close, ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { getComparativeReport } from '../../../../helpers/api';
import LoaderSkeleton from '../../../common/LoaderSkeleton';
import { Empty } from 'antd';
import { indexes } from 'd3-array';

export default function ResultComparism({ open, onClose, record, year }) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);

    getComparativeReport(record?.facilityId, year)
      .then((response) => {
        console.log('comparative', response);
        const responseData = response?.result?.data;
        setData(responseData);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  const handleContentRendering = () => {
    if (loading) {
      return (
        <Box>
          <LoaderSkeleton />
        </Box>
      );
    } else {
      if (data?.length < 1) {
        return <Empty />;
      } else {
        return renderContent();
      }
    }
  };

  const renderContent = () => {
    return (
      <Box>
        {data?.map((item, index) => (
          <Accordion key={indexes}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography component="span">Accordion 1</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {console.log(item)}
              {item?.results?.map?.((result) => {
                return result?.report?.map((resultReport) => {
                  return <div>{resultReport?.resultStatus}</div>
                });
              })}
              {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus
              ex, sit amet blandit leo lobortis eget. */}
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    );
  };

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <Box display="flex" alignItems={'center'}>
        <Typography flexGrow={1}></Typography>
        <Box padding="1rem">
          <IconButton onClick={() => onClose()}>
            <Close />
          </IconButton>
        </Box>
      </Box>
      <DialogContent>{handleContentRendering()}</DialogContent>
    </Dialog>
  );
}
