import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  counter: 0,
};

const testSlice = createSlice({
  name: 'testSlice',
  initialState,
  reducers: {
    onTestIncrement: (state) => {
      state.counter += 1;
    },
    onTestDecrement: (state) => {
      state.counter -= 1;
    },
  },
});

export const { onTestIncrement, onTestDecrement } = testSlice.actions;
export default testSlice.reducer;
