import { Close } from '@mui/icons-material';
import {
  Box,
  CardMedia,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { getReportById, getSamplePointRecord } from '../../../../helpers/api';
import LoaderSkeleton from '../../../common/LoaderSkeleton';
import { Empty } from 'antd';
import { parseString4 } from '../../../../helpers/utils';
import MUIDataTable from 'mui-datatables';

export default function ResultView({ open, onClose, record }) {
  const [reportData, setReportData] = useState();
  const [loadingReport, setLoadingReport] = useState(false);

  const [resultData, setResultData] = useState();
  const [chooseReport, setChooseReport] = useState(false);
  const [loadingResult, setLoadingResult] = useState(false);

  //console.log(resultData);

  useEffect(() => {
    fetchReportData();
  }, []);

  useEffect(() => {
    if (chooseReport) {
      fetchResultData();
    }
  }, [chooseReport]);

  const fetchReportData = () => {
    setLoadingReport(true);

    getReportById(record?.reportId)
      .then((response) => {
        const responseData = response?.result?.data;
        setReportData(responseData);
        setResultData(responseData?.samplePoints[0]);
        setChooseReport(true);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoadingReport(false));
  };

  const fetchResultData = () => {
    setLoadingResult(true);

    getSamplePointRecord(resultData?.samplePointId, record?.reportId)
      .then((response) => {
        const responseData = response?.result?.data;
        setResultData(responseData);
        setChooseReport(false);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoadingResult(false));
  };

  const handleRendering = () => {
    if (loadingReport) {
      return (
        <Box>
          <LoaderSkeleton />
        </Box>
      );
    }

    if (!loadingReport && !reportData) {
      return (
        <Box>
          <Empty />
        </Box>
      );
    }

    if (!loadingReport && reportData) {
      return renderContent();
    }
  };

  const renderContent = () => {
    return (
      <Box>
        <Box sx={{ display: 'flex', gap: 5 }}>
          <Box>
            <Typography color="textPrimary" variant="body2">
              Title
            </Typography>
            <Typography color="primary" sx={{ fontWeight: 'bold' }}>
              {reportData?.reportTitle}
            </Typography>
          </Box>
          <Box>
            <Typography color="textPrimary" variant="body2">
              Facility
            </Typography>
            <Typography color="primary" sx={{ fontWeight: 'bold' }}>
              {reportData?.facility}
            </Typography>
          </Box>
          <Box>
            <Typography color="textPrimary" variant="body2">
              Create Date
            </Typography>
            <Typography color="primary" sx={{ fontWeight: 'bold' }}>
              {new Intl.DateTimeFormat('en-NG', {
                year: 'numeric',
                month: 'short',
                day: '2-digit',
              }).format(reportData?.entryDate ? new Date(reportData.entryDate) : new Date())}
            </Typography>
          </Box>
          <Box>
            <Chip
              label={reportData?.reportType}
              color={reportData?.reportType === 'QAQC' ? 'warning' : 'success'}
            />
          </Box>
        </Box>
        <br />
        <Box
          sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', maxHeight: '300px', overflowX: 'auto' }}
        >
          {reportData?.samplePoints?.map((item, index) => (
            <Paper
              key={index}
              sx={{
                width: '150px',
                height: '150px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                //border: '1px solid gray',
                background:
                  item?.samplePointId === resultData?.samplePointId ? '#699ad0' : '#d9d9d9',
                '&:hover': {
                  background: '#f5f7ff',
                  cursor: 'pointer',
                  opacity: 0.8,
                },
              }}
              onClick={() => {
                setResultData(item);
                setChooseReport(true);
              }}
            >
              <Box
                sx={{
                  width: '50px',
                  height: '50px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {!item?.icon ? (
                  ''
                ) : (
                  <img
                    src={item?.icon}
                    alt="icon"
                    style={{ objectFit: 'contain', width: '50px', height: '50px' }}
                  />
                )}
              </Box>
              <Box>
                <Typography variant="caption" fontWeight="bold">
                  {item?.samplePointName}
                </Typography>
              </Box>
              <Typography variant="body2" sx={{ fontWeight: 'light', textTransform: 'capitalize' }}>
                {item?.measurementName}
              </Typography>
            </Paper>
          ))}
        </Box>
      </Box>
    );
  };

  const handleResultRendering = () => {
    if (loadingResult) {
      return <LoaderSkeleton />;
    }
    if (!loadingResult && !resultData) {
      return <Empty />;
    }
    if (!loadingResult && resultData) {
      return renderResultContent();
    }
  };

  const renderResultContent = () => {
    const aa = resultData;

    //const parameters = resultData?.parameters;
    // const results = resultData?.results ? resultData?.results[0]?.data || [] : [];

    // const mergedData = parameters?.map((param) => {
    //   const matchingResult = results?.find((result) => result?.parameterId === param?.parameterId);
    //   return {
    //     parameterId: param?.parameterId,
    //     unit: param?.unit,
    //     siUnit: param?.siUnit,
    //     resultValue: matchingResult ? matchingResult?.resultValue : null, // Use null if no matching result is found
    //   };
    // });

    // const allResults = resultData?.results?.flatMap((result) => result?.data);

    // const mergedData = parameters?.map((param) => {
    //   const matchingResult = allResults.find(
    //     (result) => result?.parameterId === param?.parameterId
    //   );

    //   return {
    //     parameterId: param.parameterId,
    //     unit: param.unit,
    //     siUnit: param.siUnit,
    //     resultValue: matchingResult ? matchingResult.resultValue : null, // Handle missing result values
    //   };
    // });

    // console.log('parameters', parameters);
    // console.log('results', results);
    //console.log('mergedData', mergedData);

    const { parameters, results } = resultData;

    const headers = parameters?.map((param) => ({
      parameterId: param?.parameterId,
      unit: param?.unit,
    }));

    const averages = headers?.map((header) => {
      const values = results
        ?.flatMap((resultRow) => resultRow?.data)
        ?.filter((data) => data?.parameterId === header?.parameterId)
        ?.map((data) => {
          try {
            Number(data?.resultValue);
            return data?.resultValue;
          } catch {
            return 0;
          }
        });

      const total = values?.reduce((sum, value) => sum + (value || 0), 0);
      return values?.length > 0 ? total / values.length : null;
    });

    return (
      <TableContainer>
        <Table>
          <TableHead sx={{ background: '#699ad0' }}>
            <TableRow>
              <TableCell sx={{ border: '1px solid lightgray', textAlign: 'center' }}>
                Runs
              </TableCell>
              {headers?.map((header) => (
                <TableCell
                  key={header?.parameterId}
                  sx={{ border: '1px solid lightgray', textAlign: 'center' }}
                >
                  <span dangerouslySetInnerHTML={{ __html: header?.unit }} />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {results?.map((resultRow) => (
              <TableRow key={resultRow?.dataRow}>
                <TableCell sx={{ border: '1px solid lightgray', textAlign: 'center' }}>
                  #{resultRow?.dataRow}
                </TableCell>
                {headers?.map((header) => {
                  const dataItem = resultRow?.data?.find(
                    (data) => data?.parameterId === header?.parameterId
                  );
                  return (
                    <TableCell
                      key={header?.parameterId}
                      sx={{ border: '1px solid lightgray', textAlign: 'center' }}
                    >
                      {dataItem ? dataItem?.resultValue?.toFixed(2) : ''}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
            <TableRow sx={{ background: '#f0f0f0' }}>
              <TableCell sx={{ border: '1px solid lightgray', textAlign: 'center' }}>
                Average
              </TableCell>
              {averages?.map((average, index) => (
                <TableCell
                  key={headers[index]?.parameterId}
                  sx={{ border: '1px solid lightgray', textAlign: 'center' }}
                >
                  {average !== null ? average?.toFixed(2) : '-'}
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      // <TableContainer>
      //   <Table>
      //     <TableBody>
      //       {resultData?.results?.map((result, index) => {
      //         const params = resultData?.parameters?.find(
      //           (x) => x.parameterId === result?.data[index]?.parameterId
      //         );
      //         {
      //           console.log('params', params);
      //         }
      //         <TableRow>
      //           <TableCell>{result?.dataRow}</TableCell>
      //         </TableRow>;
      //       })}
      //     </TableBody>
      //   </Table>
      // </TableContainer>

      // <TableContainer>
      //   <Table>
      //     <TableHead sx={{ background: '#699ad0' }}>
      //       <TableRow>
      //         {mergedData?.map((item, index) => (
      //           <TableCell key={index} align="center" sx={{ border: '1px solid gray' }}>
      //             <span dangerouslySetInnerHTML={{ __html: item.unit }} />
      //           </TableCell>
      //         ))}
      //       </TableRow>
      //     </TableHead>
      //     <TableBody>
      //       <TableRow>
      //         {mergedData?.map((item, index) => (
      //           <TableCell key={index} align="center" sx={{ border: '1px solid gray' }}>
      //             {item.resultValue !== null ? (
      //               item.resultValue
      //             ) : (
      //               <span style={{ color: 'gray' }}></span>
      //             )}
      //           </TableCell>
      //         ))}
      //       </TableRow>
      //     </TableBody>
      //   </Table>
      // </TableContainer>
    );

    // return (
    //   <Box
    //     sx={{
    //       display: 'flex',
    //       flexDirection: 'column',
    //       border: '0px solid lightgray',
    //       width: '100%',
    //     }}
    //   >
    //     <Box sx={{ display: 'flex', flexDirection: 'row', border: '0px solid lightgray' }}>
    //       {resultData?.parameters?.map((item, index) => (
    //         <Typography
    //           variant="body1"
    //           sx={{
    //             fontWeight: 'bold',
    //             padding: '0.5rem',
    //             borderLeft: '1px solid lightgray',
    //             borderRight: '1px solid lightgray',
    //             height: '40px',
    //             textAlign: 'center',
    //             background: '#699ad0',
    //             width: '100px',
    //           }}
    //         >
    //           {parseString4(item?.unit)}
    //         </Typography>
    //       ))}
    //     </Box>
    //     <Box sx={{ display: 'flex', flexDirection: 'row', borderBottom: '0px solid lightgray' }}>
    //       {resultData?.results?.map((item, index) => {
    //         return item?.data?.map((item2, index) => (
    //           <Typography
    //             variant="body2"
    //             sx={{
    //               padding: '0.5rem',
    //               borderLeft: '1px solid lightgray',
    //               borderRight: '1px solid lightgray',
    //               height: '40px',
    //               textAlign: 'center',
    //               width: '100px',
    //               borderBottom: '1px solid lightgray',
    //             }}
    //           >
    //             {item2?.resultValue}
    //           </Typography>
    //         ));
    //       })}
    //     </Box>
    //   </Box>
    // );
  };

  return (
    <Dialog open={open} fullWidth maxWidth="lg">
      <Box display="flex" alignItems={'center'}>
        <Typography flexGrow={1}></Typography>
        <Box padding="1rem">
          <IconButton onClick={() => onClose()}>
            <Close />
          </IconButton>
        </Box>
      </Box>
      <DialogContent>
        <Box>{handleRendering()}</Box>
        <br />
        <br />
        <Box>{handleResultRendering()}</Box>
      </DialogContent>
      <br />
    </Dialog>
  );
}
