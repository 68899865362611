import React, { useState } from 'react';
import Input from '../../../../components/common/AntInput';
import Button from '../../../../components/common/Button';
import { Form, message } from 'antd';
import { updateMeasurement } from '../../../../helpers/api';
import '../style.css';

const EditMeasurementForm = ({ closeModal, reload, name, id }) => {
  const [loading, setLoading] = useState(false);

  const editMeasurement = (values) => {
    setLoading(true);
    const payload = {
      measurementName: values.measurementName.trim().toLowerCase(),
    };
    updateMeasurement(payload, id)
      .then((res) => {
        message.success(`Update successful!`);
        setLoading(false);
        reload();
        closeModal();
      })
      .catch((error) => {
        message.error('Something went wrong');
        setLoading(false);
      });
  };
  return (
    <Form
      layout="vertical"
      initialValues={{ measurementName: name }}
      onFinish={editMeasurement}
      hideRequiredMark={true}
    >
      <Form.Item
        name="measurementName"
        label="Measurement Name"
        rules={[{ required: true, message: 'Please enter measurement name' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <Button
          htmlType="submit"
          type="primary"
          appearance="default"
          loading={loading}
          disabled={loading}
          className="mr-15"
        >
          Save
        </Button>
        <Button htmlType="button" type="secondary" onClick={closeModal} disabled={loading}>
          Cancel
        </Button>
      </Form.Item>
    </Form>
  );
};

export default EditMeasurementForm;
