import {
  Backdrop,
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { Add, CheckCircle, Close, Error, KeyboardReturn } from '@mui/icons-material';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { getFacilitiyByIdV2, updateFacility } from '../../../helpers/api';
import LoaderSkeleton from '../../common/LoaderSkeleton';
import UpdateFacilityStep1 from './update-facility/UpdateFacilityStep1';
import UpdateFacilityStep2 from './update-facility/UpdateFacilityStep2';
import UpdateFacilityStep3 from './update-facility/UpdateFacilityStep3';
import { useAppDispatch, useAppSelector } from '../../../redux/reduxstore';
import { onClearUpdateFacilityDetails } from '../../../redux/slices/facilityUpdateSlice';

const UpdateFacilityScreen = withRouter(({ history }) => {
  const facilityId = history?.location?.pathname?.split('/')[3];

  const store = useAppSelector((state) => state.facilityUpdateReducer);
  const dispatch = useAppDispatch();

  const steps = [
    { index: 0, item: 'Facility information' },
    { index: 1, item: 'Sample Points' },
    { index: 2, item: 'Consultant & Personnel' },
  ];

  const [step, setStep] = useState(0);
  const [facilityData, setFacilityData] = useState();
  const [facilityLoading, setFacilityLoading] = useState(true);

  const [showBackdrop, setShowBackdrop] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const handleUpdateFacility = () => {
    const payload = {
      ...store,
      status: facilityData?.status === 'ACTIVE' ? 1 : 0,
    };

    // const payload = {
    //   facilityName: facilityData?.facilityName,
    //   stateId: facilityData.stateId,
    //   address: facilityData.address,
    //   status: facilityData?.status === 'ACTIVE' ? 1 : 0,
    //   facilityEia: facilityData?.facilityEia,
    //   facilityPersonel: facilityData?.facilityPersonel,
    //   coordinates: facilityData?.coordinates,
    //   remarks: facilityData?.remarks,
    //   sectorId: facilityData?.sectorId,
    //   zoneId: facilityData?.zoneId,
    //   lgaId: facilityData?.lgaId,
    //   consultantId: facilityData?.consultantId,
    //   productId: facilityData?.facilityProduct?.map((item) => {
    //     return item.productId;
    //   }),
    //   productName: facilityData?.productName,
    //   rawMaterialId: facilityData?.rawMaterialId,
    //   rawMaterialName: facilityData?.rawMaterialName,
    //   wasteId: facilityData?.wasteId,
    //   wasteName: facilityData?.wasteName,
    //   samplePoints: facilityData?.samplePoints,
    //   eiaCert: facilityData?.eiaCert,
    //   facilityCategoryId: facilityData?.facilityCategoryId,
    //   registrationNo: facilityData?.registrationNo,
    // };

    setUpdating(true);
    updateFacility(facilityId, payload)
      .then((response) => {
        console.log(response);
        setSuccess(true);
      })
      .catch((err) => {
        const errorMsg = err?.data?.result?.status?.desc;
        setErrorMessage(errorMsg);
        setError(true);
      })
      .finally(() => setUpdating(false));
  };

  const fetchFacility = () => {
    getFacilitiyByIdV2(facilityId)
      .then((response) => {
        //console.log(response);
        const responseData = response?.result?.data;
        setFacilityData(responseData);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setFacilityLoading(false));
  };

  useEffect(() => {
    fetchFacility();
  }, []);

  const handleContentRendering = () => {
    if (facilityLoading) {
      return (
        <Box component={'div'} style={{ padding: '1rem' }}>
          <LoaderSkeleton />
          <br />
          <LoaderSkeleton />
          <br />
          <LoaderSkeleton />
        </Box>
      );
    }
    if (!facilityLoading && !facilityData) {
      return <Box>Not found</Box>;
    }
    if (!facilityLoading && facilityData) {
      return renderContent();
    }
  };

  const renderContent = () => {
    return (
      <Box
        component={'div'}
        sx={{
          padding: '1rem',
          margin: { lg: '0 12rem', md: '0 12rem', sm: '0rem', xs: '0rem' },
        }}
      >
        {step === 0 && (
          <UpdateFacilityStep1 onNext={(stepValue) => setStep(stepValue)} facility={facilityData} />
        )}
        {step === 1 && (
          <UpdateFacilityStep2
            onNext={(stepValue) => setStep(stepValue)}
            onPrev={(stepValue) => setStep(stepValue)}
            facility={facilityData}
          />
        )}
        {step === 2 && (
          <UpdateFacilityStep3
            onPrev={(stepValue) => setStep(stepValue)}
            onFinalSubmit={() => {
              handleUpdateFacility();
              setShowBackdrop(true);
            }}
            facility={facilityData}
          />
        )}
      </Box>
    );
  };

  const renderSuccess = () => {
    return (
      <Box sx={{ textAlign: 'center', padding: '2rem' }}>
        <CheckCircle color="success" sx={{ fontSize: '7rem' }} />
        <Typography variant="h5">Successfull</Typography>
        <br />

        <Button
          variant="outlined"
          size="large"
          sx={{ textTransform: 'none' }}
          startIcon={<KeyboardReturn />}
          onClick={() => {
            dispatch(onClearUpdateFacilityDetails());
            history.push('/facilities');
          }}
        >
          Return to Facilities
        </Button>
      </Box>
    );
  };

  const renderLoading = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          alignItems: 'center',
          padding: '2rem',
          width: 'fit-content',
        }}
      >
        <CircularProgress size={30} />
        <Typography>Editing facility. Please wait. . .</Typography>
      </Box>
    );
  };

  const renderError = () => {
    return (
      <Box sx={{ textAlign: 'center', padding: '2rem' }}>
        <Error color="error" sx={{ fontSize: '7rem' }} />
        <Typography variant="h5">Failed</Typography>
        <br />
        <Typography variant="body1" color="textSecondary">
          {errorMessage}
        </Typography>
        <br />
        <Stack direction={'row'} spacing={2}>
          <Button
            variant="outlined"
            size="large"
            sx={{ textTransform: 'none' }}
            startIcon={<KeyboardReturn />}
            onClick={() => {
              dispatch(onClearUpdateFacilityDetails());
              history.push('/facilities');
            }}
          >
            Return to Facilities
          </Button>
          <Button
            variant="outlined"
            size="large"
            sx={{ textTransform: 'none', width: '200px' }}
            startIcon={<Close />}
            onClick={() => {
              setError(false);
              setSuccess(false);
              setShowBackdrop(false);
            }}
          >
            Close
          </Button>
        </Stack>
      </Box>
    );
  };

  return (
    <Box sx={{ padding: '1rem', background: '#f9f9f9' }}>
      <Box padding="1rem" sx={{ display: 'flex', alignItems: 'center' }}>
        <Breadcrumbs sx={{ flexGrow: 1 }}>
          <Link to="/facilities">
            <Typography variant="body2" color="info.main">
              Facilities
            </Typography>
          </Link>
          <Typography variant="body2" color="text.primary">
            Edit Facility
          </Typography>
        </Breadcrumbs>
        <Box sx={{ width: '50%' }}>
          <Stepper activeStep={step} nonLinear>
            {steps.map((item) => (
              <Step key={item.index}>
                <StepLabel>{item.item}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      </Box>
      <Box>{handleContentRendering()}</Box>

      {showBackdrop && (
        <Backdrop open={showBackdrop} sx={{ zIndex: 10000 }}>
          <Box
            sx={{
              backgroundColor: 'white',
              width: '35%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {updating && renderLoading()}
            {success && renderSuccess()}
            {error && renderError()}
          </Box>
        </Backdrop>
      )}
    </Box>
  );
});

export default UpdateFacilityScreen;
