import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { getSamplePointRecord, sendSamplePointRecord } from '../../../../helpers/api';
import { useEffect, useState } from 'react';
import LoaderSkeleton from '../../../common/LoaderSkeleton';
import { Empty } from 'antd';
import { parseString4 } from '../../../../helpers/utils';
import { openNotification } from '../../../../helpers/notification';

export default function StandardResultMeasurementInputs({ samplePoint, report }) {
  //console.log(samplePoint);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [computedParameters, setComputedParameters] = useState([]);
  const [saving, setSaving] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [startUpdating, setStartUpdating] = useState(false);
  //console.log(startUpdating);

  //   console.log('data', data);
  //   console.log('computedParameters', computedParameters);

  const handleInputChange = (inputValue, parameterId) => {
    const compute = {
      parameterId: parameterId,
      value: Number(inputValue),
      entryDate: new Date().toISOString(),
    };

    setComputedParameters((prev) => {
      const existingParam = prev.find((x) => x.parameterId === parameterId);

      if (existingParam) {
        const index = prev.indexOf(existingParam);
        const updatedParameters = [...prev];
        updatedParameters.splice(index, 1, compute);
        return updatedParameters;
      }

      return [...prev, compute];
    });
  };

  const handleSaveInput = () => {
    const payload = {
      reportId: report?.reportId,
      facilityId: report?.facilityId,
      samplePointId: samplePoint.samplePointId,
      measurmentId: samplePoint?.measurementId,
      measurements: [computedParameters],
    };
    console.log('payload', payload);

    if (computedParameters?.length !== data?.parameters?.length) {
      openNotification({
        type: 'error',
        title: 'Failed',
        message: `All neccesary data have not been captured for`,
      });
    } else {
      setSaving(true);

      sendSamplePointRecord(payload)
        .then((res) => {
          const resultData = res?.result?.status?.desc;
          setRefetch(true);
          setStartUpdating(false);
          //console.log('success', res);
        })
        .catch((err) => {
          console.log('error', err);
        });
      //.finally(() => setSaving(false));
    }
  };

  const fetchData = () => {
    setLoading(true);

    getSamplePointRecord(samplePoint?.samplePointId, report?.reportId)
      .then((response) => {
        const responseData = response?.result?.data;
        setData(responseData);
        setRefetch(false);
        setComputedParameters([]);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  const handleDisplay = () => {
    if (loading) {
      return (
        <Box>
          <LoaderSkeleton />
        </Box>
      );
    }
    if (!loading && !data) {
      return (
        <Box>
          <Empty />
        </Box>
      );
    }
    if (!loading && data) {
      return (
        <Paper
          elevation={1}
          sx={{
            padding: '1rem',
            boxShadow: `0px 1px 1px rgba(0, 0, 0, 0.1), 0px 2px 10px rgba(0, 0, 0, 0.15)`,
          }}
        >
          {/* {(!data?.results || data?.minEntry < data?.results?.length || !startUpdating) &&
            renderAddComputing()}

          {startUpdating && renderUpdateComputing()} */}

          {renderComputing()}
          <br />

          {!startUpdating && renderResults()}
        </Paper>
      );
    }
  };

  const renderComputing = () => {
    if (startUpdating) {
      return renderAddComputing();
    } else {
      if (!data?.results || data?.minEntry < data?.results?.length) {
        return renderAddComputing();
      }
    }
  };

  const renderAddComputing = () => {
    return (
      <Box>
        <Box sx={{ padding: '1rem 0rem' }}>
          <Typography>
            Measuring <strong>{data?.groupName}</strong> at <strong>{data?.samplePointName}</strong>
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', gap: 2, marginBottom: '1rem', flexWrap: 'wrap' }}>
          {data?.parameters?.map((item) => {
            return (
              <TextField
                key={item?.parameterId}
                label={parseString4(item?.unit)}
                sx={{ maxWidth: '100px' }}
                onBlur={(e) => handleInputChange(e.target.value, item?.parameterId)}
              />
            );
          })}
        </Box>
        <Box sx={{ marginBottom: '1rem' }}>
          <Button
            size="large"
            variant="contained"
            onClick={handleSaveInput}
            disabled={computedParameters?.length < data?.parameters?.length - 1}
            startIcon={saving && <CircularProgress sx={{ color: '#FFFFFF' }} size={20} />}
            sx={{ textTransform: 'capitalize' }}
          >
            <Typography>Save</Typography>
          </Button>
        </Box>
      </Box>
    );
  };

  // const renderUpdateComputing = () => {
  //   return (
  //     <Box>
  //       <Box sx={{ padding: '1rem 0rem' }}>
  //         <Typography>
  //           Measuring <strong>{data?.groupName}</strong> at <strong>{data?.samplePointName}</strong>
  //         </Typography>
  //       </Box>
  //       <Box sx={{ display: 'flex', gap: 2, marginBottom: '1rem', flexWrap: 'wrap' }}>
  //         {data?.results?.map((item) => {
  //           return item?.data?.map((item2, index) => {
  //             const unit = data?.parameters?.find((x) => x?.parameterId === item2?.parameterId)
  //               ?.unit;
  //             return (
  //               <TextField
  //                 key={item2?.parameterId}
  //                 value={item2?.resultValue}
  //                 label={parseString4(unit)}
  //                 sx={{ maxWidth: '100px' }}
  //                 onBlur={(e) => handleInputChange(e.target.value, item?.parameterId)}
  //               />
  //             );
  //           });
  //         })}
  //       </Box>
  //       <Box sx={{ marginBottom: '1rem' }}>
  //         <Button
  //           size="large"
  //           variant="contained"
  //           onClick={handleSaveInput}
  //           //disabled={computedParameters?.length < data?.parameters?.length - 1}
  //           startIcon={saving && <CircularProgress sx={{ color: '#FFFFFF' }} size={20} />}
  //           sx={{ textTransform: 'capitalize' }}
  //         >
  //           <Typography>Save</Typography>
  //         </Button>
  //       </Box>
  //     </Box>
  //   );
  // };

  const renderResults = () => {
    return (
      <Box>
        <TableContainer>
          <Table>
            {data?.results?.length > 0 && (
              <TableHead sx={{ background: '#699ad0' }}>
                <TableRow>
                  <TableCell sx={{ border: '1px solid lightgray', textAlign: 'center' }}>
                    Runs
                  </TableCell>
                  {data?.parameters?.map((header) => (
                    <TableCell
                      key={header?.parameterId}
                      sx={{ border: '1px solid lightgray', textAlign: 'center' }}
                    >
                      <span dangerouslySetInnerHTML={{ __html: header?.unit }} />
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              {data?.results?.map((resultRow) => (
                <TableRow key={resultRow?.dataRow}>
                  <TableCell sx={{ border: '1px solid lightgray', textAlign: 'center' }}>
                    #{resultRow?.dataRow}
                  </TableCell>
                  {data?.parameters?.map((header) => {
                    const dataItem = resultRow?.data?.find(
                      (x) => x.parameterId === header.parameterId
                    );
                    return (
                      <TableCell
                        key={header?.parameterId}
                        sx={{ border: '1px solid lightgray', textAlign: 'center' }}
                      >
                        {dataItem ? dataItem?.resultValue?.toFixed(2) : ''}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <br />
        {!startUpdating && (
          <Button variant="contained" size="large" onClick={() => setStartUpdating(true)}>
            Update
          </Button>
        )}
      </Box>
    );
  };

  useEffect(() => {
    if (samplePoint?.samplePointId) {
      fetchData();
    }
  }, [samplePoint?.samplePointId]);

  useEffect(() => {
    if (refetch) {
      fetchData();
    }
  }, [refetch]);

  return (
    <Box>
      <Box>{handleDisplay()}</Box>
      <br />
      <br />
    </Box>
  );
}
