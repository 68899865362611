import { Close, DateRange, LockOpen, Lock } from '@mui/icons-material';
import {
  Box,
  Dialog,
  Grid2,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { generateFacilityReport } from '../../../../helpers/api';
import LoaderSkeleton from '../../../common/LoaderSkeleton';
import { Empty, Popconfirm } from 'antd';
import FacilityReportQuaterLock from '../facility-result-quarters/FacilityReportQuaterLock';
import FacilityStandardReport from './StandardResultMeasurement';

export default function FacilityStandardReportQuarters({
  //open,
  //onClose,
  record,
  userCategory,
  userPermission,
  onSelectReport,
}) {
  const userCat = userCategory.trim().toLowerCase();
  const userPerm =
    userPermission && userPermission.length > 0
      ? userPermission.map((mp) => mp.trim().toLowerCase())
      : [];

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [dialogLock, setDialogLock] = useState(false);
  const [row, setRow] = useState();
  const [selectedReport, setSelectedReport] = useState();
  const [refetch, setRefetch] = useState(false);
  const [dialogStandardReport, setDialogStandardReport] = useState(false);
  const [displayYears, setDisplayYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  //console.log("sel", selectedReport);
  //console.log(data);

  const generateYears = () => {
    const startYear = 2022;
    const currentYear = new Date().getFullYear();
    const years = [];

    for (let year = startYear; year <= currentYear; year++) {
      years.push(year);
    }

    setDisplayYears(years);
  };

  const fetchData = () => {
    setLoading(true);
    generateFacilityReport(record?.facilityId, selectedYear)
      .then((response) => {
        //console.log(response);
        const responseData = response?.result?.data;
        setData(responseData);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
        setRefetch(false);
      });
  };

  const handleRendering = () => {
    if (loading) {
      return (
        <Box>
          <LoaderSkeleton />
        </Box>
      );
    }

    if (!loading && !data) {
      return (
        <Box>
          <Empty />
        </Box>
      );
    }

    if (!loading && data) {
      return renderContent();
    }
  };

  const renderContent = () => {
    return (
      <Box>
        <Box display={'flex'} alignItems={'center'}>
          <Stack direction={'column'} sx={{ padding: '0 2rem', flexGrow: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }} color="primary">
              {record?.facilityName}
            </Typography>
            <Typography variant="body2" color="textDisabled">
              Select quarter and start reporting
            </Typography>
          </Stack>
          <Box sx={{ padding: '0 2rem' }}>
            <Select
              value={selectedYear}
              size="small"
              onChange={(e) => setSelectedYear(e.target.value)}
            >
              {displayYears?.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
        <Box>{renderByCategory()}</Box>
      </Box>
    );
  };

  const renderByCategory = () => {
    switch (userCat) {
      case 'edm':
        return renderEdmDisplay();
      case 'consultant':
        return renderConsultantDisplay();
    }
  };

  const renderEdmDisplay = () => {
    return (
      <Grid2 container spacing={2} sx={{ padding: '2rem' }}>
        {data?.map((item) => (
          <Grid2 key={item?.reportId} size={{ lg: 3, md: 3, sm: 3, xs: 2 }}>
            <Paper
              onClick={() => {
                if (item?.reportType === 'QAQC') {
                  //setDialogStandardReport(true);
                  //setSelectedReport(item);
                  onSelectReport(false, item);
                  console.log(item)
                }
              }}
              sx={{
                padding: '1rem',
                background: item?.reportType === 'QAQC' ? null : '#d9d9d9',
                borderWidth: item?.reportType === 'QAQC' ? 1 : 0,
                borderStyle: item?.reportType === 'QAQC' ? 'solid' : 'none',
                borderColor: item?.reportType === 'QAQC' ? 'primary.main' : 'none',
                '&:hover': {
                  //background: '#f5f7ff',
                  background: item?.reportType === 'QAQC' ? '#f5f7ff' : 'normal',
                  cursor: item?.reportType === 'QAQC' ? 'pointer' : 'normal',
                  opacity: item?.reportType === 'QAQC' ? 0.8 : 1,
                  //opacity: 0.8,
                },
              }}
            >
              <Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {item?.reportType === 'QAQC' ? (
                    <Typography variant="caption" color="info" flexGrow={1}>
                      Reserved for QAQC
                    </Typography>
                  ) : (
                    <Typography variant="caption" color="error" flexGrow={1}>
                      Not for QAQC
                    </Typography>
                  )}
                  <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                    {item?.reportType === 'QAQC' ? (
                      <LockOpen color="primary" />
                    ) : (
                      <Lock color="error" />
                    )}
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <DateRange sx={{ fontSize: '100px' }} color="disabled" />
                </Box>
                <Box sx={{ textAlign: 'center' }}>
                  <Typography variant="body1">{item?.reportTitle}</Typography>
                  <Typography variant="body2" color="textDisabled">
                    {item?.remarks}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Grid2>
        ))}
      </Grid2>
    );
  };

  const renderConsultantDisplay = () => {
    return (
      <Grid2 container spacing={2} sx={{ padding: '2rem' }}>
        {data?.map((item) => (
          <Grid2 key={item?.reportId} size={{ lg: 3, md: 3, sm: 3, xs: 2 }}>
            <Paper
              onClick={() => {
                if (item?.reportType !== 'QAQC') {
                  setDialogStandardReport(true);
                  setSelectedReport(item);
                  //onShow(false);
                  //alert("")
                }
              }}
              sx={{
                padding: '1rem',
                background: item?.reportType !== 'QAQC' ? null : '#d9d9d9',
                borderWidth: item?.reportType !== 'QAQC' ? 1 : 0,
                borderStyle: item?.reportType !== 'QAQC' ? 'solid' : 'none',
                borderColor: item?.reportType !== 'QAQC' ? 'primary.main' : 'none',
                '&:hover': {
                  //background: '#f5f7ff',
                  background: item?.reportType !== 'QAQC' ? '#f5f7ff' : 'normal',
                  cursor: item?.reportType !== 'QAQC' ? 'pointer' : 'normal',
                  opacity: item?.reportType !== 'QAQC' ? 0.8 : 1,
                  //opacity: 0.8,
                },
              }}
            >
              <Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {item?.reportType !== 'QAQC' ? (
                    <Typography variant="caption" color="info" flexGrow={1}></Typography>
                  ) : (
                    <Typography variant="caption" color="error" flexGrow={1}>
                      Reserved for QAQC
                    </Typography>
                  )}
                  <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                    {item?.reportType !== 'QAQC' ? (
                      <LockOpen color="primary" />
                    ) : (
                      <Lock color="error" />
                    )}
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <DateRange sx={{ fontSize: '100px' }} color="disabled" />
                </Box>
                <Box sx={{ textAlign: 'center' }}>
                  <Typography variant="body1">{item?.reportTitle}</Typography>
                  <Typography variant="body2" color="textDisabled">
                    {item?.remarks}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Grid2>
        ))}
      </Grid2>
    );
  };

  useEffect(() => {
    generateYears();
  }, []);

  useEffect(() => {
    fetchData();
  }, [selectedYear]);

  useEffect(() => {
    if (refetch) {
      fetchData();
    }
  }, [refetch, selectedYear]);

  return (
    <Box>
      <Box sx={{ padding: '1rem' }}>{handleRendering()}</Box>

      {/* <Dialog open={open} fullWidth maxWidth="md">
        <Box display="flex" alignItems={'center'}>
          <Typography flexGrow={1}></Typography>
          <Box padding="1rem">
            <IconButton onClick={() => onClose()}>
              <Close />
            </IconButton>
          </Box>
        </Box>
        <Box sx={{ padding: '1rem' }}>{handleRendering()}</Box>
      </Dialog>

      {dialogStandardReport && (
        <FacilityStandardReport
          open={dialogStandardReport}
          onClose={() => setDialogStandardReport(false)}
          report={selectedReport}
        />
      )} */}
    </Box>
  );
}
