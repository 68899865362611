import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { CaretDownFilled } from '@ant-design/icons/lib/icons';
import { Link } from 'react-router-dom';
//import { Menu, Dropdown } from 'antd';
// import Avatar from 'assets/img/user-avatar.png';
import { UserOutlined } from '@ant-design/icons';
import { AppContext } from '../../store/AppContext';
import { UserContext } from '../../store/UserContext';
import {
  Avatar,
  Box,
  Chip,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  Typography,
} from '@mui/material';
import { Logout, Person2 } from '@mui/icons-material';

const NavUser = styled.div`
  background: transparent;
  display: flex;
  align-items: center;

  img {
    margin-right: 8px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
  }

  .name {
    text-transform: capitalize;
    font-family: var(--font-family);
    color: var(--dark);
    font-size: 14px;
  }
  .ant-dropdown-menu-item {
    font-size: 14px !important;
  }
`;

export default ({ className }) => {
  const {
    state: { auth },
  } = useContext(AppContext);
  const { dispatch } = useContext(AppContext);
  const { userData, getUserData } = useContext(UserContext);
  // const { firstname, lastname, userId } = auth.result.data;
  console.log(userData);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    getUserData();
  }, []);

  const logout = () => {
    dispatch({
      type: 'LOGOUT',
    });
  };

  const menu = (
    <Menu>
      <Menu.Item>
        <Link to="/settings/profile">Profile</Link>
      </Menu.Item>
      {/* <Menu.Item>
        <Link to="/settings">Settings</Link>
      </Menu.Item> */}
      <Menu.Item>
        <Link to="/" onClick={logout}>
          Log Out
        </Link>
      </Menu.Item>
    </Menu>
  );

  return (
    // <NavUser className={className}>
    //   <Dropdown overlay={menu} trigger={['hover', 'click']}>
    //     <a className="ant-dropdown-link" href="/#" onClick={(e) => e.preventDefault()}>
    //       <UserOutlined style={{ fontSize: '14px', marginRight: '5px' }} />
    //       {userData && userData !== null ? (
    //         <span className="name">
    //           {userData.firstname} {userData.lastname}
    //         </span>
    //       ) : null}

    //       <CaretDownFilled
    //         style={{
    //           fontSize: '14px',
    //           paddingLeft: '5px',
    //           color: '#66788a',
    //           verticalAlign: 'middle',
    //         }}
    //       />
    //     </a>
    //   </Dropdown>
    // </NavUser>
    <Box>
      <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
        <Chip label={userData?.role} />
        <Box
          sx={{
            display: 'flex',
            //gap: 1,
            alignItems: 'center',
            '&:hover': {
              opacity: 0.8,
              cursor: 'pointer',
            },
          }}
          onClick={handleClick}
        >
          <IconButton onClick={handleClick} size="small">
            <Avatar sx={{ height: '35px', width: '35px' }}>
              <Typography sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
                {userData?.firstname?.charAt(0)}
                {userData?.lastname?.charAt(0)}
              </Typography>
            </Avatar>
          </IconButton>
          <Typography color="textPrimary">
            {userData?.firstname} {userData?.lastname}
          </Typography>
        </Box>
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        slotProps={{
          paper: {
            sx: {
              marginTop: '10px',
              // boxShadow: 'none',
              // filter: 'drop-shadow(0px 1px 1px rgba(200, 200, 200, 0.25))',
              width: '200px',
              // border: '1px solid lightgray',
            },
          },
        }}
      >
        <ListItemButton>
          <ListItemIcon>
            <Person2 />
          </ListItemIcon>
          <ListItemText primary={<Link to="/settings/profile">Profile</Link>} />
        </ListItemButton>
        <ListItemButton>
          <ListItemIcon>
            <Logout />
          </ListItemIcon>
          <ListItemText
            primary={
              <Link to="/" onClick={logout}>
                Log Out
              </Link>
            }
          />
        </ListItemButton>
      </Menu>
    </Box>
  );
};
