import { Close } from '@mui/icons-material';
import {
  Alert,
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { lockReport } from '../../../../helpers/api';
import { CircularProgress } from '@mui/material';
import { openNotification } from '../../../../helpers/notification';

export default function FacilityReportQuaterLock({ open, onClose, report, onRefetch }) {
  const [loading, setLoading] = useState(false);

  const handleRevertQuarter = () => {
    setLoading(true);
    lockReport(report?.reportId)
      .then((response) => {
        onRefetch();
        onClose();
        //console.log(response);

        openNotification({
          type: 'success',
          title: 'Successful',
          message: 'Quarter has been successfully reserved',
        });
      })
      .catch((error) => {
        const errMsg = error?.data?.result?.status?.desc;
        onClose();
        openNotification({
          type: 'error',
          title: 'Failed',
          message: errMsg,
        });
      })
      .finally(() => setLoading(false));
  };
  return (
    <Dialog open={open} fullWidth maxWidth="xs">
      <Box display="flex" alignItems={'center'}>
        <Typography flexGrow={1}></Typography>
        <Box padding="1rem">
          <IconButton onClick={() => onClose()}>
            <Close />
          </IconButton>
        </Box>
      </Box>
      <DialogContent>
        <Alert severity="info">Reserving {report?.reportTitle} for QAQC</Alert>
        <br />
        <Stack direction={'column'} justifyContent={'center'}>
          <Typography
            variant="body2"
            sx={{ textAlign: 'center' }}
          >{`You are about to reserve this quarter for QAQC.`}</Typography>
          <Typography variant="caption" color="warning" sx={{ textAlign: 'center' }}>
            {' '}
            Are you sure you want to continue?
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ padding: '1rem 1.5rem' }}>
        <Chip
          label="No! Don't resever it"
          variant="outlined"
          color="primary"
          onClick={() => onClose()}
          disabled={loading}
        />
        <Chip
          label="Yes! Reserve it"
          variant="filled"
          color="primary"
          onClick={handleRevertQuarter}
          disabled={loading}
          icon={loading && <CircularProgress size={20} />}
        />
      </DialogActions>
    </Dialog>
  );
}
