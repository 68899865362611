import React, { useState, useEffect } from 'react';
import { Form, message, Row, Col, Divider, Select } from 'antd';
import Input from '../../../../components/common/AntInput';
import Button from '../../../../components/common/Button';
import Table from '../../../../components/common/Table';
import { addParameters, getStandardParameters } from '../../../../helpers/api';
import { Box, IconButton } from '@mui/material';
import { Delete } from '@mui/icons-material';

const AddParameterForm = ({ closeModal, reload, measurementId }) => {
  const [parameterCollection, setParameterCollection] = useState([]);
  const [loading, setLoading] = useState(false);
  const [parameterData, setParameterData] = useState([]);
  const [parameterList, setParameterList] = useState([]);

  const [form] = Form.useForm();

  useEffect(() => {
    getParameters();
  }, []);

  const getParameters = () => {
    getStandardParameters()
      .then((response) => {
        const parameters = response?.result?.data;
        const modified = parameters?.map((item) => {
          return {
            value: item.parameterId,
            label: `${item.unit} - (${item.siUnit})`,
          };
        });
        setParameterData(parameters);
        setParameterList(modified);
      })
      .catch((error) => {
        message.error('Failed to fetch parameters');
      });
  };

  const handleRemoveParameter = (parameterId) => {
    const filteredData = parameterCollection.filter((x) => x.parameterId !== parameterId);
    setParameterCollection(filteredData);
  };

  const handlePreviewParameters = () => {
    const columns = [
      {
        title: 'Parameter Name',
        dataIndex: 'unit',
        key: 'unit',
      },
      {
        title: 'SI Unit',
        dataIndex: 'siUnit',
        key: 'siUnit',
      },
      {
        title: 'NESERA limit',
        dataIndex: 'nesrea',
        key: 'nesrea',
      },
      {
        title: 'World Bank Limit',
        dataIndex: 'world',
        key: 'world',
      },
      {
        title: '',
        dataIndex: 'parameterId',
        key: 'parameterId',
        render: (parameterId) => (
          <IconButton size="small" onClick={() => handleRemoveParameter(parameterId)}>
            <Delete color="error" fontSize="small" />
          </IconButton>
        ),
      },
    ];

    if (parameterCollection?.length > 0) {
      return (
        <Box sx={{ padding: '1rem 0' }}>
          <Table
            columns={columns}
            dataSource={parameterCollection}
            rowClassName="parameter__table"
          />
        </Box>
      );
    } else return null;
  };

  const handleAddParameters = (values) => {
    const exists = parameterCollection?.find((x) => x.parameterId === values.parameterId);
    if (!exists) {
      const selected = parameterData?.find((x) => x.parameterId === values.parameterId);
      if (selected) {
        const param = {
          parameterId: selected.parameterId,
          unit: selected.unit,
          siUnit: selected.siUnit,
          nesrea: values.nesrea,
          world: values.world,
        };

        setParameterCollection([...parameterCollection, param]);
        form.resetFields();
      }
    } else {
      message.error(`Parameters already added`);
    }
  };

  // HANDLE  PARAMETERS
  const handleSubmitParameters = () => {
    setLoading(true);
    addParameters(parameterCollection, measurementId)
      .then((res) => {
        setLoading(false);
        message.success(`Parameters added successfully`);
        reload();
        closeModal();
      })
      .catch((error) => {
        setLoading(false);
        message.error(error);
      });
  };

  return (
    <>
      <Form form={form} layout="vertical" onFinish={handleAddParameters} hideRequiredMark={true}>
        <Row gutter={5}>
          <Col lg={24} md={24} sm={24} xs={24}>
            <Form.Item
              name="parameterId"
              label="Parameter Name"
              rules={[{ required: true, message: 'Please select paramter' }]}
            >
              <Select
                showSearch
                allowClear
                optionFilterProp="label"
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? '')
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? '').toLowerCase())
                }
                //onChange={(paramterId) => console.log(paramterId)}
                options={parameterList}
              />
            </Form.Item>
          </Col>
          <Col lg={12} md={12} sm={12} xs={24}>
            <Form.Item
              name="nesrea"
              label="NESREA Limit"
              rules={[{ required: true, message: 'NESREA limit is required' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={12} md={12} sm={12} xs={24}>
            <Form.Item
              name="world"
              label="World Bank Limit"
              rules={[{ required: true, message: 'World Bank limit is required' }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={24} md={24} sm={24} xs={24}>
            <Form.Item className="text-right">
              <Button htmlType="submit" type="primary" appearance="default">
                Add
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Divider />

      <div>
        <Button
          onClick={handleSubmitParameters}
          htmlType="button"
          type="primary"
          appearance="default"
          className="mr-15"
          loading={loading}
          disabled={!parameterCollection.length || loading}
        >
          Save
        </Button>

        <Button onClick={closeModal} htmlType="button" type="secondary" disabled={loading}>
          Cancel
        </Button>
      </div>
      <div>{handlePreviewParameters()}</div>
    </>
  );
};

export default AddParameterForm;
