import React, { useState } from 'react';
import { Form, message, Row, Col, InputNumber } from 'antd';
import Input from '../../../../components/common/AntInput';
import Button from '../../../../components/common/Button';
import { updateParameter } from '../../../../helpers/api';
import { Stack } from '@mui/material';

const EditParamterForm = ({
  data,
  closeModal,
  // unit,
  // id,
  // siUnit,

  // nesrea,
  // world,
  // nesreaLowerLimit,
  // nesreaUpperLimit,
  // worldLowerLimit,
  // worldUpperLimit,

  reload,
}) => {
  const [loading, setLoading] = useState(false);

  const initValues = {
    unit: data?.unit,
    siUnit: data?.siUnit,
    world: data?.world,
    nesrea: data?.nesrea,
    nesreaLowerLimit: data?.nesreaLowerLimit,
    nesreaUpperLimit: data?.nesreaUpperLimit,
    worldLowerLimit: data?.worldLowerLimit,
    worldUpperLimit: data?.worldUpperLimit,
  };

  const handleEditParameter = (values) => {
    setLoading(true);

    const payload = {
      parameterId: data?.parameterId,
      measurementId: data?.measurementId,
      nesreaUpperLimit: 0,
      nesreaLowerLimit: 0,
      worldUpperLimit: 0,
      worldLowerLimit: 0,
      nesrea: values?.nesrea,
      world: values?.world,
    };

    console.log('payload', payload);

    updateParameter(data?.parameterId, payload)
      .then((res) => {
        message.success(`Updated successfully!`);
        setLoading(false);
        reload();
        closeModal();
      })
      .catch((error) => {
        message.error(`Something went wrong. Please try again`);
        setLoading(false);
      });
  };

  // const handleContent = () => {
  //   if (!unit) return <div>Loading</div>;
  //   else return content();
  // };

  return (
    <Form
      layout="vertical"
      hideRequiredMark={true}
      className="edit__parameter__form"
      initialValues={initValues}
      onFinish={handleEditParameter}
    >
      <Row gutter={10}>
        <Col lg={24} md={24} sm={24} xs={24}>
          <Form.Item
            name="unit"
            label="Parameter Name"
            disabled
            rules={[{ required: true, message: 'Please enter Parameter name' }]}
          >
            <Input disabled />
          </Form.Item>
        </Col>
        <Col lg={12} md={12} sm={12} xs={24}>
          <Form.Item
            name="nesrea"
            label="NESREA Limit"
            rules={[{ required: true, message: 'NESREA Limit is required' }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col lg={12} md={12} sm={12} xs={24}>
          <Form.Item
            name="world"
            label="World Bank Limit"
            rules={[{ required: true, message: 'World Bank limit is required' }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col lg={24} md={24} sm={12} xs={24}>
          <Form.Item>
            <Button
              type="primary"
              appearance="default"
              htmlType="submit"
              loading={loading}
              disabled={loading}
              className="mr-10"
            >
              Save
            </Button>
            <Button type="secondary" onClick={closeModal} disabled={loading}>
              Cancel
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default EditParamterForm;
