import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  Grid2,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import {
  getFacilityCategoryV2,
  getLgaV2,
  getProductV2,
  getRawMaterialV2,
  getSectorV2,
  getStateV2,
  getWasteV2,
  getZoneV2,
} from '../../../../helpers/api';
import { useAppDispatch, useAppSelector } from '../../../../redux/reduxstore';
import { onStoreUpdateFacilityDetails } from '../../../../redux/slices/facilityUpdateSlice';
import { openNotification } from '../../../../helpers/notification';

export default function UpdateFacilityStep1({ onNext, facility }) {
  const store = useAppSelector((state) => state.facilityUpdateReducer);
  const dispatch = useAppDispatch();

  const facilityStatuses = [
    {
      status: 'ACTIVE',
      displayName: 'Active',
    },
    {
      status: 'INACTIVE',
      displayName: 'Inactive',
    },
  ];

  const initSector = {
    sectorId: '',
    sectorName: '',
  };

  const initZone = {
    zoneId: '',
    zoneName: '',
  };

  const initState = {
    stateId: '',
    stateName: '',
  };

  const initLga = {
    lgaId: '',
    lgaName: '',
  };

  const initProduct = {
    productId: '',
    productName: '',
  };

  const initRawMaterial = {
    rawMaterialId: '',
    rawMaterialName: '',
  };

  const initWaste = {
    wasteId: '',
    wasteName: '',
  };

  const [categoryLoading, setCategoryLoading] = useState(false);
  const [categoryData, setCategoryData] = useState([]);

  const [sectorLoading, setSectorLoading] = useState(false);
  const [sectorData, setSectorData] = useState([]);
  const [sectorSelected, setSectorSelected] = useState(initSector);

  const [productLoading, setProductLoading] = useState(false);
  const [productData, setProductData] = useState([]);
  const [productSelected, setProductSelected] = useState(initProduct);
  const [productInput, setProductInput] = useState('');
  const [productIds, setProductIds] = useState([]);
  const [productNames, setproductNames] = useState([]);
  const [productOpen, setProductOpen] = useState(false);

  const [rawMaterialLoading, setRawMaterialLoading] = useState(false);
  const [rawMaterialData, setRawMaterialData] = useState([]);
  const [rawMaterialSelected, setRawMaterialSelected] = useState(initRawMaterial);
  const [rawMaterialIds, setRawMaterialIds] = useState([]);
  const [rawMaterialInput, setRawMaterialInput] = useState('');
  const [rawMaterialNames, setRawMaterialNames] = useState([]);

  const [wasteLoading, setWasteLoading] = useState(false);
  const [wasteData, setWasteData] = useState([]);
  const [wasteSelected, setWasteSelected] = useState(initWaste);
  const [wasteInput, setWasteInput] = useState('');
  const [wasteIds, setWasteIds] = useState([]);
  const [wasteNames, setWasteNames] = useState([]);

  const [zoneLoading, setZoneLoading] = useState(false);
  const [zoneData, setZoneData] = useState([]);
  const [zoneSelected, setZoneSelected] = useState(initZone);

  const [stateLoading, setStateLoading] = useState(false);
  const [stateData, setStateData] = useState([]);
  const [stateSelected, setStateSelected] = useState(initState);
  const [stateDisabled, setStateDisabled] = useState(true);

  const [lgaLoading, setLgaLoading] = useState(false);
  const [lgaData, setLgaData] = useState([]);
  const [lgaSelected, setLgaSelected] = useState(initLga);
  const [lgaDisabled, setLgaDisabled] = useState(true);

  const [eiaBase64, setEiaBase64] = useState('');

  const formik = useFormik({
    initialValues: {
      facilityName: '',
      registrationNo: '',
      address: '',
      latitude: '',
      longitude: '',
      facilityCategoryId: '',
      sectorId: '',
      zoneId: '',
      stateId: '',
      lgaId: '',
      status: '',
      facilityEia: '',
      eiaCert: '',
      remarks: '',
    },
    validationSchema: Yup.object({
      //facilityName: Yup.string().required('Field is required'),
    }),
    onSubmit: (values) => handleSubmit(values),
  });

  const handleSubmit = (values) => {
    const reduxPayload = {
      //step 1
      facilityName: values?.facilityName,
      registrationNo: values?.registrationNo,
      address: values?.address,
      coordinates: {
        latitude: values?.latitude,
        longitude: values?.longitude,
      },
      facilityCategoryId: values?.facilityCategoryId,
      sectorId: values?.sectorId,
      zoneId: values?.zoneId,
      stateId: values?.stateId,
      lgaId: values?.lgaId,
      status: values?.status,
      productId: productIds,
      productName: productNames,
      rawMaterialId: rawMaterialIds,
      rawMaterialName: rawMaterialNames,
      wasteId: wasteIds,
      wasteName: wasteNames,
      facilityEia: store?.facilityEia,
      eiaCert: store?.eiaCert,
      remarks: store?.remarks,

      //step 2
      samplePoints: !store?.samplePoints?.length ? [] : store?.samplePoints,

      //step 3
      consultantId: !store?.consultantId?.length ? [] : store?.consultantId,
      facilityPersonel: !store?.facilityPersonel?.length ? [] : store?.facilityPersonel,
    };

    if (productIds?.length < 1 && productNames?.length < 1) {
      return openNotification({
        type: 'error',
        title: 'You must select or enter at least one product',
      });
    } else if (rawMaterialIds?.length < 1 && rawMaterialNames?.length < 1) {
      return openNotification({
        type: 'error',
        title: 'You must select or enter at least one raw material',
      });
    } else if (wasteIds?.length < 1 && wasteNames?.length < 1) {
      return openNotification({
        type: 'error',
        title: 'You must select or enter at least one waste product',
      });
    } else {
      dispatch(onStoreUpdateFacilityDetails(reduxPayload));
      onNext(1);
    }
  };

  const getInitalData = () => {
    formik.setValues({
      facilityName: !store?.facilityName ? facility?.facilityName : store?.facilityName,
      registrationNo: !store?.registrationNo ? facility?.registrationNo : store?.registrationNo,
      address: !store?.address ? facility?.address : store.address,
      latitude: !store?.latitude ? facility?.coordinates?.latitude : store?.latitude,
      longitude: !store?.longitude ? facility?.coordinates?.longitude : store?.longitude,

      facilityCategoryId: !store?.facilityCategoryId
        ? facility?.facilityCategoryId
        : store?.facilityCategoryId,

      sectorId: !store?.sectorId ? facility?.sectorId : store?.sectorId,
      zoneId: !store?.zoneId ? facility?.zoneId : store?.zoneId,
      stateId: !store?.stateId ? facility?.stateId : store?.stateId,
      lgaId: !store?.lgaId ? facility?.lgaId : store?.lgaId,
      status: !store?.status ? facility?.status : store?.status,
    });

    setproductNames(store?.productName);
    setRawMaterialNames(store?.rawMaterialName);
    setWasteNames(store?.wasteName);
  };

  const handleAddProductIds = (selectedProduct) => {
    if (selectedProduct) {
      const exists = productIds?.includes(selectedProduct?.productId);
      if (!exists) {
        setProductIds((prev) => [...prev, selectedProduct?.productId]);
      }
      setProductSelected(initProduct);
    }
  };

  const handleAddProductNames = (e) => {
    if (productInput && e.key === 'Enter') {
      e.preventDefault();
      const exists = productNames?.includes(productInput);
      if (!exists) {
        setproductNames((prev) => [...prev, productInput]);
      }
      setProductInput('');
    }
  };

  const handleRemoveProductIds = (productId) => {
    const index = productIds.indexOf(productId);
    const newProductIds = [...productIds];
    newProductIds.splice(index, 1);
    setProductIds(newProductIds);
  };

  const handleRemoveProductNames = (product) => {
    const index = productNames.indexOf(product);
    const newProductNames = [...productNames];
    newProductNames.splice(index, 1);
    setproductNames(newProductNames);
  };

  const handleAddRawMaterialIds = (selectedRawMaterial) => {
    const exists = rawMaterialIds?.includes(selectedRawMaterial?.rawMaterialId);
    if (!exists) {
      setRawMaterialIds((prev) => [...prev, selectedRawMaterial?.rawMaterialId]);
    }
    setRawMaterialSelected(initRawMaterial);
  };

  const handleAddRawMaterialNames = (e) => {
    if (rawMaterialInput && e.key === 'Enter') {
      e.preventDefault();
      const exists = rawMaterialNames?.includes(rawMaterialInput);
      if (!exists) {
        setRawMaterialNames((prev) => [...prev, rawMaterialInput]);
      }
      setRawMaterialInput('');
    }
  };

  const handleRemoveRawMaterialIds = (rawMaterialId) => {
    const index = rawMaterialIds.indexOf(rawMaterialId);
    const newRawMaterialIds = [...rawMaterialIds];
    newRawMaterialIds.splice(index, 1);
    setRawMaterialIds(newRawMaterialIds);
  };

  const handleRemoveRawMaterialNames = (rawMaterial) => {
    const index = rawMaterialNames.indexOf(rawMaterial);
    const newRawMaterialNames = [...rawMaterialNames];
    newRawMaterialNames.splice(index, 1);
    setRawMaterialNames(newRawMaterialNames);
  };

  const handleAddWasteIds = (selectedWaste) => {
    const exists = wasteIds?.includes(selectedWaste?.wasteId);
    if (!exists) {
      setWasteIds((prev) => [...prev, selectedWaste?.wasteId]);
    }
    setWasteSelected(initWaste);
  };

  const handleAddWasteNames = (e) => {
    if (wasteInput && e.key === 'Enter') {
      e.preventDefault();
      const exists = wasteNames?.includes(wasteInput);
      if (!exists) {
        setWasteNames((prev) => [...prev, wasteInput]);
      }
      setWasteInput('');
    }
  };

  const handleRemoveWasteIds = (wasteId) => {
    const index = wasteIds.indexOf(wasteId);
    const newWasteIds = [...wasteIds];
    newWasteIds.splice(index, 1);
    setWasteIds(newWasteIds);
  };

  const handleRemoveWasteNames = (waste) => {
    const index = wasteNames.indexOf(waste);
    const newWasteNames = [...wasteNames];
    newWasteNames.splice(index, 1);
    setWasteNames(newWasteNames);
  };

  const handleEiaFileUploadChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setEiaBase64(reader.result);

        // formik.setValues({
        //   ...formik.values,
        //   facilityEia: 1,
        //   eiaCert: reader.result,
        // });
      };
      reader.onerror = (error) => {
        console.error('Error converting file to Base64:', error);
      };
    }
  };

  const fetchCategory = () => {
    setCategoryLoading(true);
    getFacilityCategoryV2()
      .then((response) => {
        const responseData = response?.result?.data;
        setCategoryData(responseData);
      })
      .catch((err) => {})
      .finally(() => setCategoryLoading(false));
  };

  const fetchSector = () => {
    setSectorLoading(true);
    getSectorV2()
      .then((response) => {
        const responseData = response?.result?.data;
        setSectorData(responseData);
      })
      .catch((err) => {})
      .finally(() => setSectorLoading(false));
  };

  const fetchProduct = () => {
    setProductLoading(true);
    getProductV2()
      .then((response) => {
        const responseData = response?.result?.data;
        setProductData(responseData);
      })
      .catch((err) => {})
      .finally(() => setProductLoading(false));
  };

  const fetchRawMaterial = () => {
    setRawMaterialLoading(true);
    getRawMaterialV2()
      .then((response) => {
        const responseData = response?.result?.data;
        setRawMaterialData(responseData);
      })
      .catch((err) => {})
      .finally(() => setRawMaterialLoading(false));
  };

  const fetchWaste = () => {
    setWasteLoading(true);
    getWasteV2()
      .then((response) => {
        const responseData = response?.result?.data;
        setWasteData(responseData);
      })
      .catch((err) => {})
      .finally(() => setWasteLoading(false));
  };

  const fetchZone = () => {
    setZoneLoading(true);
    getZoneV2()
      .then((response) => {
        const responseData = response?.result?.data;
        setZoneData(responseData);
      })
      .catch((err) => {})
      .finally(() => setZoneLoading(false));
  };

  const fetchState = () => {
    setStateLoading(true);
    getStateV2()
      .then((response) => {
        const responseData = response?.result?.data;
        setStateData(responseData);
      })
      .catch((err) => {})
      .finally(() => setStateLoading(false));
  };

  const fetchLga = () => {
    setLgaLoading(true);
    getLgaV2()
      .then((response) => {
        const responseData = response?.result?.data;
        setLgaData(responseData);
      })
      .catch((err) => {})
      .finally(() => setLgaLoading(false));
  };

  const getInitialSector = () => {
    if (store?.sectorId) {
      const sector = sectorData?.find((x) => x?.sectorId === store?.sectorId);
      if (sector) {
        setSectorSelected({
          sectorId: sector?.sectorId,
          sectorName: sector?.sectorName,
        });
      }
    } else if (facility?.sectorId) {
      const sector = sectorData?.find((x) => x?.sectorId === facility?.sectorId);
      if (sector) {
        setSectorSelected({
          sectorId: sector?.sectorId,
          sectorName: sector?.sectorName,
        });
      }
    }
  };

  const getInitialProducts = () => {
    if (store?.productId?.length > 0) {
      setProductIds(store?.productId);
    } else if (facility?.facilityProduct?.length > 0) {
      const ids = facility?.facilityProduct?.map((item) => {
        return item?.productId;
      });
      setProductIds(ids);
    }
  };

  const getInitialRawMaterials = () => {
    if (store?.rawMaterialId?.length > 0) {
      setRawMaterialIds(store?.rawMaterialId);
    } else if (facility?.facilityRawMaterial?.length > 0) {
      const ids = facility?.facilityRawMaterial?.map((item) => {
        return item?.rawMaterialId;
      });
      setRawMaterialIds(ids);
    }
  };

  const getInitialWastes = () => {
    if (store?.wasteId?.length > 0) {
      setWasteIds(store?.wasteId);
    } else if (facility?.facilityWaste?.length > 0) {
      const ids = facility?.facilityWaste?.map((item) => {
        return item?.wasteId;
      });
      setWasteIds(ids);
    }
  };

  const getInitialZone = () => {
    if (store?.zoneId) {
      const zone = zoneData?.find((x) => x?.zoneId === store?.zoneId);
      if (zone) {
        setZoneSelected({
          zoneId: zone?.zoneId,
          zoneName: zone?.zoneName,
        });
      }
    } else if (facility?.zoneId) {
      const zone = zoneData?.find((x) => x?.zoneId === facility?.zoneId);
      if (zone) {
        setZoneSelected({
          zoneId: zone?.zoneId,
          zoneName: zone?.zoneName,
        });
      }
    }
  };

  const getInitialState = () => {
    if (store?.stateId) {
      const state = stateData?.find((x) => x?.stateId === store?.stateId);
      if (state) {
        setStateSelected({
          stateId: state?.stateId,
          stateName: state?.stateName,
        });
      }
    } else if (facility?.stateId) {
      const state = stateData?.find((x) => x?.stateId === facility?.stateId);
      if (state) {
        setStateSelected({
          stateId: state?.stateId,
          stateName: state?.stateName,
        });
      }
    }
  };

  const getInitialLga = () => {
    if (store?.lgaId) {
      const lga = lgaData?.find((x) => x?.lgaId === store?.lgaId);
      if (lga) {
        setLgaSelected({
          lgaId: lga?.lgaId,
          lgaName: lga?.lgaName,
        });
      }
    } else if (facility?.lgaId) {
      const lga = lgaData?.find((x) => x?.lgaId === facility?.lgaId);
      if (lga) {
        setLgaSelected({
          lgaId: lga?.lgaId,
          lgaName: lga?.lgaName,
        });
      }
    }
  };

  useEffect(() => {
    fetchCategory();
  }, []);

  useEffect(() => {
    fetchSector();
  }, []);

  useEffect(() => {
    fetchProduct();
  }, []);

  useEffect(() => {
    fetchRawMaterial();
  }, []);

  useEffect(() => {
    fetchWaste();
  }, []);

  useEffect(() => {
    fetchZone();
  }, []);

  useEffect(() => {
    fetchState();
  }, []);

  useEffect(() => {
    fetchLga();
  }, []);

  useEffect(() => {
    if (facility) {
      getInitalData();
    }
  }, [facility]);

  useEffect(() => {
    getInitialSector();
  }, [sectorData]);

  useEffect(() => {
    getInitialProducts();
  }, [productData]);

  useEffect(() => {
    getInitialRawMaterials();
  }, [rawMaterialData]);

  useEffect(() => {
    getInitialWastes();
  }, [wasteData]);

  useEffect(() => {
    getInitialZone();
  }, [zoneData]);

  useEffect(() => {
    getInitialState();
  }, [stateData]);

  useEffect(() => {
    getInitialLga();
  }, [lgaData]);

  return (
    <Box component={'div'}>
      <form onSubmit={formik.handleSubmit}>
        <Grid2 container spacing={2}>
          <Grid2 size={{ lg: 12, md: 12, sm: 12, xs: 12 }}>
            <Typography component={'div'} variant="body1" color="textPrimary">
              <strong>Facility Information</strong>
            </Typography>
            <Typography component={'div'} variant="body2" color="textSecondary">
              Brief details about the facility.
            </Typography>
          </Grid2>
          <Grid2 size={{ lg: 6, md: 6, sm: 6, xs: 12 }}>
            <TextField
              name={'facilityName'}
              label="Facility Name"
              variant="outlined"
              value={formik.values.facilityName}
              fullWidth
              onChange={formik.handleChange}
              required
              sx={{ backgroundColor: '#FFFFFF' }}
            />
          </Grid2>
          <Grid2 size={{ lg: 6, md: 6, sm: 6, xs: 12 }}>
            <TextField
              name={'registrationNo'}
              label="Registration No."
              variant="outlined"
              value={formik.values.registrationNo}
              fullWidth
              onChange={formik.handleChange}
              required
              sx={{ backgroundColor: '#FFFFFF' }}
            />
          </Grid2>
          <Grid2 size={{ lg: 12, md: 12, sm: 12, xs: 12 }}>
            <TextField
              name={'address'}
              label="Address"
              variant="outlined"
              value={formik.values.address}
              fullWidth
              onChange={formik.handleChange}
              required
              multiline
              rows={2}
              sx={{ backgroundColor: '#FFFFFF' }}
            />
          </Grid2>
          <Grid2 size={{ lg: 6, md: 6, sm: 6, xs: 12 }}>
            <TextField
              name={'latitude'}
              label="Latitude"
              variant="outlined"
              value={formik.values.latitude}
              fullWidth
              onChange={formik.handleChange}
              //required
              sx={{ backgroundColor: '#FFFFFF' }}
            />
          </Grid2>
          <Grid2 size={{ lg: 6, md: 6, sm: 6, xs: 12 }}>
            <TextField
              name={'longitude'}
              label="Longitude"
              variant="outlined"
              value={formik.values.longitude}
              fullWidth
              onChange={formik.handleChange}
              //required
              sx={{ backgroundColor: '#FFFFFF' }}
            />
          </Grid2>
          <Grid2 size={{ lg: 6, md: 6, sm: 6, xs: 12 }}>
            <FormControl fullWidth>
              <InputLabel>Category</InputLabel>
              <Select
                name="facilityCategoryId"
                label="Category"
                value={formik.values.facilityCategoryId}
                required
                onChange={formik.handleChange}
                startAdornment={categoryLoading && <CircularProgress size={20} />}
                sx={{ backgroundColor: '#FFFFFF' }}
              >
                {categoryData?.map((category) => (
                  <MenuItem key={category?.categoryId} value={category?.categoryId}>
                    {category?.categoryName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid2>
          <Grid2 size={{ lg: 6, md: 6, sm: 6, xs: 12 }}>
            <Autocomplete
              fullWidth
              disablePortal
              value={sectorSelected}
              options={sectorData}
              getOptionLabel={(option) => option.sectorName}
              onChange={(e, value) => {
                if (value) {
                  setSectorSelected(value);
                  formik.setValues({ ...formik.values, sectorId: value?.sectorId });
                }
              }}
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Sector"
                  size="medium"
                  sx={{ backgroundColor: '#FFFFFF' }}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: sectorLoading && <CircularProgress size={20} color="primary" />,
                  }}
                />
              )}
            />
          </Grid2>
          <Grid2 size={{ lg: 12, md: 12, sm: 12, xs: 12 }}>
            <br />
            <Typography component={'div'} variant="body1" color="textPrimary">
              <strong>Production Activities</strong>
            </Typography>
            <Typography component={'div'} variant="body2" color="textSecondary">
              Information about the facility's main products, raw materials and waste products.
            </Typography>
          </Grid2>
          <Grid2 size={{ lg: 12, md: 12, sm: 12, xs: 12 }}>
            <Autocomplete
              fullWidth
              disablePortal
              //open={productOpen}
              //onOpen={() => {alert("dsd")}}
              value={productSelected}
              options={productData}
              getOptionLabel={(option) => option.productName}
              onChange={(e, value) => handleAddProductIds(value)}
              onInputChange={(e, value) => setProductInput(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Product"
                  onKeyDown={(e) => {
                    handleAddProductNames(e);
                  }}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: productLoading && (
                      <CircularProgress size={20} color="primary" />
                    ),
                  }}
                />
              )}
              sx={{ backgroundColor: '#FFFFFF' }}
            />
            <Box sx={{ padding: '0.5rem 0', display: 'flex', gap: 1, flexWrap: 'wrap' }}>
              {productIds?.map((productId) => {
                const product = productData?.find((x) => x.productId === productId);
                return (
                  <Chip
                    label={product?.productName}
                    onDelete={() => handleRemoveProductIds(productId)}
                  />
                );
              })}
              {productNames?.map((product) => {
                return <Chip label={product} onDelete={() => handleRemoveProductNames(product)} />;
              })}
            </Box>
          </Grid2>
          <Grid2 size={{ lg: 12, md: 12, sm: 12, xs: 12 }}>
            <Autocomplete
              fullWidth
              disablePortal
              value={rawMaterialSelected}
              options={rawMaterialData}
              getOptionLabel={(option) => option.rawMaterialName}
              onChange={(e, value) => handleAddRawMaterialIds(value)}
              onInputChange={(e, value) => setRawMaterialInput(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Raw Material"
                  sx={{ backgroundColor: '#FFFFFF' }}
                  onKeyDown={(e) => handleAddRawMaterialNames(e)}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: rawMaterialLoading && (
                      <CircularProgress size={20} color="primary" />
                    ),
                  }}
                />
              )}
            />
            <Box sx={{ padding: '0.5rem 0', display: 'flex', gap: 1, flexWrap: 'wrap' }}>
              {rawMaterialIds?.map((rawMaterialId) => {
                const rawMaterial = rawMaterialData?.find((x) => x.rawMaterialId === rawMaterialId);
                return (
                  <Chip
                    label={rawMaterial?.rawMaterialName}
                    onDelete={() => handleRemoveRawMaterialIds(rawMaterialId)}
                  />
                );
              })}
              {rawMaterialNames?.map((rawMaterial) => {
                return (
                  <Chip
                    label={rawMaterial}
                    onDelete={() => handleRemoveRawMaterialNames(rawMaterial)}
                  />
                );
              })}
            </Box>
          </Grid2>
          <Grid2 size={{ lg: 12, md: 12, sm: 12, xs: 12 }}>
            <Autocomplete
              fullWidth
              disablePortal
              value={wasteSelected}
              options={wasteData}
              getOptionLabel={(option) => option.wasteName}
              onChange={(e, value) => handleAddWasteIds(value)}
              onInputChange={(e, value) => setWasteInput(value)}
              sx={{ backgroundColor: '#FFFFFF' }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Waste Product"
                  onKeyDown={(e) => handleAddWasteNames(e)}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: wasteLoading && <CircularProgress size={20} color="primary" />,
                  }}
                />
              )}
            />
            <Box sx={{ padding: '0.5rem 0', display: 'flex', gap: 1, flexWrap: 'wrap' }}>
              {wasteIds?.map((wasteId) => {
                const waste = wasteData?.find((x) => x.wasteId === wasteId);
                return (
                  <Chip label={waste?.wasteName} onDelete={() => handleRemoveWasteIds(wasteId)} />
                );
              })}
              {wasteNames?.map((waste) => {
                return <Chip label={waste} onDelete={() => handleRemoveWasteNames(waste)} />;
              })}
            </Box>
          </Grid2>
          <Grid2 size={{ lg: 4, md: 4, sm: 4, xs: 12 }}>
            <Autocomplete
              fullWidth
              disablePortal
              value={zoneSelected}
              options={zoneData}
              getOptionLabel={(option) => option.zoneName}
              onChange={(e, value) => {
                if (value) {
                  setZoneSelected(value);
                  formik.setValues({ ...formik.values, zoneId: value?.zoneId });

                  const newState = stateData?.filter((x) => x.zoneId === value?.zoneId);
                  setStateDisabled(false);
                  setStateData(newState);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Zone"
                  sx={{ backgroundColor: '#FFFFFF' }}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: zoneLoading && <CircularProgress size={20} color="primary" />,
                  }}
                />
              )}
            />
          </Grid2>
          <Grid2 size={{ lg: 4, md: 4, sm: 4, xs: 12 }}>
            <Autocomplete
              fullWidth
              disablePortal
              value={stateSelected}
              options={stateData?.filter((x) => x.zoneId === zoneSelected?.zoneId)}
              disabled={!zoneSelected?.zoneId && stateDisabled}
              getOptionLabel={(option) => option.stateName}
              onChange={(e, value) => {
                if (value) {
                  setStateSelected(value);
                  formik.setValues({ stateId: value?.stateId });

                  const newLga = lgaData?.filter((x) => x?.stateId === value?.stateId);
                  setLgaDisabled(false);
                  setLgaData(newLga);
                }
              }}
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="State"
                  size="medium"
                  sx={{ backgroundColor: '#FFFFFF' }}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: stateLoading && <CircularProgress size={20} color="primary" />,
                  }}
                />
              )}
            />
          </Grid2>
          <Grid2 size={{ lg: 4, md: 4, sm: 4, xs: 12 }}>
            <Autocomplete
              fullWidth
              disablePortal
              value={lgaSelected}
              options={lgaData?.filter(
                (x) => x?.stateName?.toLowerCase() === stateSelected?.stateName?.toLowerCase()
              )}
              disabled={!stateSelected?.stateId && lgaDisabled}
              getOptionLabel={(option) => option.lgaName}
              onChange={(e, value) => {
                if (value) {
                  setLgaSelected(value);
                  formik.setValues({ lgaId: value?.lgaId });
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Lga"
                  sx={{ backgroundColor: '#FFFFFF' }}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: lgaLoading && <CircularProgress size={20} color="primary" />,
                  }}
                />
              )}
            />
          </Grid2>
          <Grid2 size={{ lg: 6, md: 6, sm: 6, xs: 12 }}>
            <FormControl fullWidth>
              <InputLabel>Facility Status</InputLabel>
              <Select
                name={'status'}
                value={formik.values.status}
                label="Facility Status"
                onChange={formik.handleChange}
                sx={{ backgroundColor: '#FFFFFF' }}
              >
                {facilityStatuses?.map((item) => (
                  <MenuItem key={item?.status} value={item?.status}>
                    {item?.displayName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid2>
          <Grid2 size={{ lg: 6, md: 6, sm: 6, xs: 12 }}>
            <TextField
              label="EIA Certificate"
              value={eiaBase64}
              variant="outlined"
              fullWidth
              slotProps={{
                input: {
                  startAdornment: (
                    <Button
                      variant="contained"
                      color="inherit"
                      component="label"
                      size="small"
                      style={{ whiteSpace: 'nowrap', marginRight: '5px', textTransform: 'none' }}
                    >
                      Upload
                      <input type="file" hidden onChange={handleEiaFileUploadChange} />
                    </Button>
                  ),
                },
                readOnly: true,
              }}
            />
          </Grid2>
          <Grid2 size={{ lg: 12, md: 12, sm: 12, xs: 12 }}>
            <Button
              type="submit"
              variant="contained"
              sx={{ textTransform: 'none', width: '100px' }}
              size="large"
            >
              Next
            </Button>
          </Grid2>
        </Grid2>
      </form>
    </Box>
  );
}
