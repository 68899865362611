import React, { useEffect } from 'react';
import 'jquery';
import { HashRouter, BrowserRouter, Switch, Route } from 'react-router-dom';
import { GlobalRoutes } from './routes';
import PaymentVerifier from './pages/paymentverifier';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './redux/reduxstore';

export default () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.paystack.co/v1/inline.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Switch>
            <Route path="/paymentverifier" component={PaymentVerifier} />
            <HashRouter>
              <GlobalRoutes />
            </HashRouter>
          </Switch>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};
