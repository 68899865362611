import React from 'react';
import PageContainer from '../components/common/PageContainer';
import Layout from '../components/common/Layout';
import Facilities from '../components/screens/facilities';
import FacilityScreen from '../components/screens/facilities2/FacilityScreen';

export default function Facility(props) {
  return (
    <Layout headerTitle="facilities">
      <PageContainer id="facilities">
        {/* <Facilities props={props} /> */}
        <FacilityScreen props={props} />
      </PageContainer>
    </Layout>
  );
}
